"use client";
import { Cinzel_Decorative } from "next/font/google";
import type { ButtonHTMLAttributes, DetailedHTMLProps } from "react";

interface Props
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  color?: string;
  className?: string;
  title: string;
  onClick?: () => void;
}

const cinzel = Cinzel_Decorative({
  weight: ["400", "700", "900"],
  preload: false,
});

export function SalesButton({
  color = "brown",
  className,
  title,
  onClick,
  ...rest
}: Props) {
  return (
    <button
      {...rest}
      onClick={onClick}
      className={`${cinzel.className} ${
        color === "gray"
          ? "bg-buttonSale grayscale"
          : color === "brown"
          ? "bg-buttonSale hover:bg-buttonSaleHover"
          : "bg-buttonGreen hover:bg-buttonGreenHover"
      } transition-all duration-300 ease-in-out bg-cover bg-no-repeat font-bold text-white pb-1 ${className}`}
    >
      {title}
    </button>
  );
}
