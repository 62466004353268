"use client";
import "@rainbow-me/rainbowkit/styles.css";
import {
  darkTheme,
  getDefaultConfig,
  RainbowKitProvider,
} from "@rainbow-me/rainbowkit";
import {
  cookieStorage,
  createStorage,
  http,
  WagmiProvider,
} from "wagmi";
import { polygon } from "wagmi/chains";
import { PropsWithChildren } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

export function ProviderWagmi({ children }: PropsWithChildren) {
  const projectId = "4ebbf2eddb8738c4c84cd8082b5e9756";
  const queryClient = new QueryClient();

  const config = getDefaultConfig({
    appName: "Farmine Lands",
    projectId,
    chains: [polygon],
    storage: createStorage({
      storage: cookieStorage,
    }),
    transports: {
      [polygon.id]: http(),
    },
    ssr: true,
  });

  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider
          modalSize="compact"
          theme={darkTheme()}
        >
          {children}
        </RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  );
}
