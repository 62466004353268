import { IMetadata } from "@/interfaces/INfts";
import { create } from "zustand";

export interface IOpenChestStore {
  openModal: boolean;
  setOpenModal: (openModal: boolean) => void;
  nft: IMetadata | undefined;
  setNft: (nft: IMetadata) => void;
  chestSize: number;
  setChestSize: (mint: number) => void;
  mint: boolean;
  setMint: (mint: boolean) => void;
  metadataUrl: string;
  setMetadataUrl: (metadataUrl: string) => void;
  error: boolean;
  setError: (error: boolean) => void;
  success: boolean;
  setSuccess: (success: boolean) => void;
}

export const useOpenChestStore = create<IOpenChestStore>((set, get) => ({
  openModal: false,
  setOpenModal: (openModal) => set({ openModal }),
  nft: undefined,
  setNft: (nft) => set({ nft }),
  chestSize: 0,
  setChestSize: (chestSize) => set({ chestSize }),
  mint: false,
  setMint: (mint) => set({ mint }),
  metadataUrl: "",
  setMetadataUrl: (metadataUrl) => set({ metadataUrl }),
  error: false,
  setError: (error) => set({ error }),
  success: false,
  setSuccess: (success) => set({ success }),
}));
