import React from 'react';

const Analytics = () => (
  <>
    {/* Google Analytics */}
    <script
      async
      src="https://www.googletagmanager.com/gtag/js?id=G-Y97Q9DVE61"
    />
    <script
      dangerouslySetInnerHTML={{
        __html: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-Y97Q9DVE61');
        `,
      }}
    />

    {/* Meta Pixel */}
    <script
      dangerouslySetInnerHTML={{
        __html: `
          !function(f,b,e,v,n,t,s) {
            if(f.fbq) return; 
            n = f.fbq = function() {
              n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
            };
            if(!f._fbq) f._fbq = n;
            n.push = n.push || [];
            n.loaded = true;
            n.version = '2.0';
            n.queue = [];
            t = b.createElement(e); 
            t.async = true;
            t.src = v;
            s = b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s);
          }(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
          
          fbq('init', '1735450600622894');
          fbq('track', 'PageView');
        `,
      }}
    />

    {/* NoScript for Meta Pixel */}
    <noscript>
      <img
        height="1"
        width="1"
        style={{ display: 'none' }}
        src="https://www.facebook.com/tr?id=1735450600622894&ev=PageView&noscript=1"
        alt=""
      />
    </noscript>
  </>
);

export default Analytics;
