"use client";
import Modal from "@mui/material/Modal";
import Image from "next/image";
import { SalesButton } from "./SalesButton";
import { useMintStore } from "@/stores/mintStore";
import { Inter } from "next/font/google";

const inter = Inter({ subsets: ["latin"] });

export function BuyModal() {
  const { error, openModal, setOpenModal, hash, mint, success, chestSize } =
    useMintStore();

  const image =
    chestSize === 1
      ? "/sales/small_lands.png"
      : chestSize === 2
      ? "/sales/medium_lands.png"
      : "/sales/large_lands.png";

  return (
    <Modal
      open={openModal}
      onClose={undefined}
      className={`${inter.className} flex items-center justify-center`}
    >
      <div className="relative flex items-center justify-center text-white rounded-2xl w-auto h-auto max-w-[460px] max-h-[634px]">
        <Image
          src={"/sales/modal_chest.png"}
          alt="small_lands"
          width={10000}
          height={10000}
          draggable={false}
          className="w-full h-full absolute object-fill top-0 z-10"
        />

        <div className="z-50 p-4 flex flex-col items-end w-full">
          {!success && !error && (
            <div className="flex flex-col items-center justify-center w-full gap-10 py-20 p-10">
              <h1 className="text-xl font-bold text-yellow-primary text-center">
                {mint
                  ? "Nice approved! Now accept the purchase in your metamask."
                  : "Loading... Please approve the transaction."}
              </h1>
              <svg
                aria-hidden="true"
                className="w-14 h-14 text-gray-200 animate-spin fill-wood-primary"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
            </div>
          )}
          {success && !error && (
            <div className="flex flex-col items-center justify-center w-full gap-6">
              <h1 className="text-xl font-bold text-yellow-primary text-center">
                You have successfully acquired a{" "}
                {chestSize === 1
                  ? "Small"
                  : chestSize === 2
                  ? "Medium"
                  : "Large"}{" "}
                Lands chest
              </h1>
              <Image
                src={image}
                loader={() => image}
                alt="lands"
                width={1000}
                height={1000}
                draggable={false}
                className="w-full rounded-lg"
              />
              <SalesButton
                title="Continue"
                color="green"
                onClick={() => setOpenModal(false)}
                className="w-[174px] h-[44px]"
              />
            </div>
          )}

          {!success && error && (
            <div className="flex flex-col items-center justify-between w-full gap-10 p-10">
              <Image
                src={"/sales/flower.svg"}
                loader={() => "/sales/flower.svg"}
                alt="small_lands"
                width={1000}
                height={1000}
                draggable={false}
                className="w-1/4 rounded-lg"
              />
              <h1 className="text-xl font-bold text-red-700 text-center">
                Transaction failed, check on block explorer
              </h1>
              <SalesButton
                title="Close"
                color="brown"
                onClick={() => setOpenModal(false)}
                className="w-[174px] h-[44px]"
              />
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}
