"use client";
import { PropsWithChildren } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthProvider } from "@/context/AuthContext";
import Analytics from "../Analytics";
import { BuyModal } from "../BuyModal";
import { NftModal } from "../NftModal";
import { ProviderWagmi } from "./WagmiProvider";

export function Layout({ children }: PropsWithChildren) {
  return (
    <ProviderWagmi>
      <AuthProvider>
        <ToastContainer autoClose={1500} theme="dark" className={"mt-10"} />
        <Analytics />
        <BuyModal />
        <NftModal />
        <div id="connect" className="fixed top-14 right-14 z-[99999]" />
        {children}
      </AuthProvider>
    </ProviderWagmi>
  );
}
